import React from 'react'
import { graphql } from 'gatsby'
import Layout, { Main } from '../wrappers/layout'
import { useStoreContext } from '../wrappers/store'
import useSettings from '../hooks/useSettings'
import { GatsbyImage } from '../components/image'
import { PriceTag, PriceRange } from '../components/pricing'
import {
  ProductOption,
  useProductSelection
} from '../components/product-options'
// import { ProductCarousel } from "../components/carousel";
// import ProductCard from "../components/product-card";
import { ProductListingGrid } from '../components/product-listing'
import { useProductMetafields } from '../components/metafields'
import { AddToCart } from '../components/add-to-cart'
import { RecommendedProductsGrid } from '../components/recommended'
import ArticleCard from '../components/article-card'
import { Columns } from '../components/article-list'

function ProductCopy({ html }) {
  return (
    <div
      className='product-description'
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

function getAltTextCaption(string) {
  const hasCaption = string?.includes('ALT=')
  const parsed = string?.split('ALT=')
  const caption = hasCaption ? parsed?.[0] || null : null
  const alt = hasCaption ? parsed?.[1] : string
  return { caption, alt }
  // const hasAltText = string?.includes('ALT=')
  // const parsed = string?.split('ALT=')
  // const caption = hasAltText ? (parsed?.[0] || null) : string
  // const alt = hasAltText ? parsed?.[1] : string
  // return { caption, alt }
}

function ProductGallerySlide({ data, altText, counter }) {
  const { caption, alt } = getAltTextCaption(altText)
  return (
    <div className='product-gallery-slide'>
      <div className='slide-image-wrapper'>
        <GatsbyImage data={data} alt={alt} />
        {caption && <span className='slide-image-counter'>{counter}</span>}
      </div>
      {/* {caption && <span className="caption">{caption}</span>} */}
    </div>
  )
}

function ProductGallery({ media, selectedVariant }) {
  // Add counter numbers to all assets
  const slides = media.map((asset, i) => {
    return {
      ...asset,
      counter: i + 1
    }
  })

  // Find the highlighted image based on variant selection
  const highlight = selectedVariant?.image
    ? slides.find((m) => m.shopifyId === selectedVariant.image.id)
    : slides[0]

  return (
    <div
      className={`product-gallery ${
        slides.length > 1 ? 'is-gallery' : ''
      } hide-scrollbars`}
    >
      <div
        className={`product-gallery-wrapper ${
          slides.length > 2 ? 'gridded' : 'stacked'
        }`}
      >
        {highlight && (
          <ProductGallerySlide
            data={highlight?.large}
            altText={highlight?.altText}
            counter={highlight?.counter}
            key={`media-${highlight.shopifyId}`}
          />
        )}
        {slides?.map((asset, i) =>
          asset.shopifyId !== highlight.shopifyId ? (
            <ProductGallerySlide
              data={asset.large}
              altText={asset.altText}
              counter={asset.counter}
              key={`media-${asset.shopifyId}`}
            />
          ) : null
        )}
      </div>
    </div>
  )
}

function useProductGalleryCaptions(media) {
  return media.map((asset, i) => {
    return {
      ...getAltTextCaption(asset.altText),
      counter: i + 1
    }
  })
}

function ProductGalleryCaptions({ media }) {
  const galleryCaptions = useProductGalleryCaptions(media)
  const mediaHasCaptions = galleryCaptions.find((c) => c.caption) ? true : false

  return (
    mediaHasCaptions && (
      <div className='outerx2'>
        <div className='flex-spaced inner outerx2 bdr-b'>
          <h1>Images</h1>
        </div>
        <ol className='product-image-captions'>
          {media?.map((asset, i) => {
            const { caption } = getAltTextCaption(asset.altText)
            return caption ? (
              <li data-counter={i + 1} key={`fig${i}`}>
                {caption}
              </li>
            ) : null
          })}
        </ol>
      </div>
    )
  )
}

export default function ProductTemplate({ data }) {
  const { resolveProductData } = useStoreContext()

  const copy = useSettings()

  const {
    shopifyId,
    title,
    media,
    vendor,
    priceRange,
    options,
    descriptionHtml,
    variants,
    metafields
  } = resolveProductData([data.product])?.[0] || {}

  const { variant_title, product_title, enquiry_note } =
    useProductMetafields(metafields)

  const productTitle = product_title || title?.replace(vendor, '')

  const {
    selectedOptions,
    selectedVariant,
    handleOptionSelect,
    optionsWithVariants,
    hasSelected
  } = useProductSelection(variants, options)

  return (
    <Layout>
      <Main>
        <div className='max-page-width mauto'>
          <div className='grid has-gaps'>
            <div className='span-8'>
              <ProductGallery media={media} selectedVariant={selectedVariant} />
            </div>
            <div className='span-4 product-page-info'>
              <hgroup>
                <h1>{productTitle}</h1>
                {variant_title && <h3>{variant_title}</h3>}
                <em>{vendor}</em>
                <h2>
                  {selectedVariant && hasSelected ? (
                    <PriceTag
                      currencyCode={selectedVariant?.price?.currencyCode}
                      amount={selectedVariant?.price?.amount}
                    />
                  ) : (
                    <PriceRange priceRange={priceRange} />
                  )}
                </h2>
              </hgroup>

              {!enquiry_note ? (
                <div className='outerx4'>
                  {variants.length > 1 &&
                    optionsWithVariants?.map((option) => (
                      <ProductOption
                        option={option}
                        handleOptionSelect={handleOptionSelect}
                        selectedOptions={selectedOptions}
                        key={option.name}
                      />
                    ))}
                  <div className='outerx2'>
                    <AddToCart
                      selectedVariant={selectedVariant}
                      label={copy.addToCart}
                    />
                  </div>
                </div>
              ) : (
                <div className='outerx4'>
                  <div className='flex-spaced inner outerx2 bdr-b'>
                    <h1>Enquire now</h1>
                  </div>
                  <ProductCopy html={enquiry_note} />
                  <div className='outerx2'>
                    <a
                      href={`mailto:${copy.enquiryEmail}?subject=Enquiry: ${title}`}
                      className='btn'
                    >
                      {copy.enquiryEmail}
                    </a>
                  </div>
                </div>
              )}

              <div className='outerx2'>
                {descriptionHtml && (
                  <>
                    <div className='flex-spaced inner outerx2 bdr-b'>
                      <h1>Description</h1>
                    </div>
                    <ProductCopy html={descriptionHtml} />
                  </>
                )}

                <ProductGalleryCaptions media={media} />
              </div>
            </div>
          </div>
        </div>

        <h1 className='align-center outerx4 page-gutters'>
          {copy.relatedContent}
        </h1>

        {/* TODO: Also related content set in contentful */}
        {(false || data.maker) && (
          <div className='max-xl mauto'>
            <Columns>
              {data?.maker && (
                <ArticleCard
                  title={data?.maker.name}
                  subtitle={'About the maker'}
                  link={`/makers/${data.maker.slug}`}
                  cover={data?.maker.heroImage}
                  description={data?.maker.description}
                />
              )}
            </Columns>
          </div>
        )}

        {data.productByMaker?.nodes.length > 0 ? (
          <div className='page-gutters'>
            <ProductListingGrid
              className='small'
              products={data.productByMaker?.nodes}
            />
          </div>
        ) : (
          <div className='page-gutters'>
            <RecommendedProductsGrid className='small' productId={shopifyId} />
          </div>
        )}

        {/* {data.productByMaker?.nodes.length > 0 ? (
					<ProductCarousel className="outerx2">
						{data.productByMaker.nodes.map(item => 
							<ProductCard  
								title={item.title.replace(item.vendor, "")} // TODO: subtitle metafield
								subtitle={item.vendor}
								linkTo={`/products/${item.handle}`}
								gatsbyImageData={item.featuredImage.small} 
								priceRange={item.priceRange}
								metafields={item.metafields}
								key={item.handle}
							/>	
						)}
					</ProductCarousel>
				) : (
					<RecommendedProducts productId={shopifyId} />
				)} */}
      </Main>
    </Layout>
  )
}

export { ProductPageMeta as Head } from '../components/meta'

export const query = graphql`
  query ($handle: String!, $vendor: String) {
    product: shopifyProduct(handle: { eq: $handle }) {
      shopifyId
      title
      handle
      descriptionHtml
      vendor
      totalInventory
      media: images {
        ... on ShopifyProductImage {
          ...ShopifyMediaImage
        }
      }
      options {
        name
        values
      }
      priceRange: priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      metafields {
        key
        value
      }
      variants {
        shopifyId
        title
        inventoryQuantity
        availableForSale
        selectedOptions {
          name
          value
        }
        image {
          id
        }
        metafields {
          key
          value
        }
      }
      metafields {
        key
        value
      }
    }

    productByMaker: allShopifyProduct(
      limit: 12
      filter: { vendor: { eq: $vendor }, handle: { ne: $handle } }
    ) {
      nodes {
        ...ProductCard
      }
    }

    maker: contentfulMaker(name: { eq: $vendor }) {
      name
      slug
      description {
        text: description
      }
      heroImage {
        ...ContentfulMedia
      }
    }
  }
`
