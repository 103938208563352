// Dependencies
import React from 'react'
import { useQuery } from 'urql'
import { useStoreContext } from '../wrappers/store'
import ProductCard from './product-card'
import { ProductCarousel } from './carousel'

function useProductsQuery(query = ``, variables = {}, debug) {
  if (debug) console.log(debug)
  const { localCountryCode } = useStoreContext()
  const [result] = useQuery({
    query,
    variables: { ...variables, countryCode: localCountryCode }
  })
  if (result?.error) console.error(result.error.message)
  return result?.data || {}
}

const RECOMMENDATIONS_QUERY = `
	query RecommendedProducts($productId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
		productRecommendations(productId: $productId) {
			shopifyId: id
			title
			handle
			vendor
			featuredImage {
				url
				width
				height
			}
			priceRange {
				maxVariantPrice {
					amount
					currencyCode
				}
				minVariantPrice {
					amount
					currencyCode
				}
			}
			metafields(identifiers: [
        {
          namespace: "custom", 
          key: "product_title"
        }
      ]) {
        key
        value
      }
		}
	}
`

export function RecommendedProducts({ productId }) {
  const { productRecommendations } = useProductsQuery(
    RECOMMENDATIONS_QUERY,
    {
      productId
    },
    'running useProductsQuery(recommended)'
  )

  return (
    <ProductCarousel className='outerx2'>
      {productRecommendations?.map((item) => (
        <ProductCard
          title={item.title.replace(item.vendor, '')} // TODO: subtitle metafield
          subtitle={item.vendor}
          linkTo={`/products/${item.handle}`}
          shopifyMedia={item.featuredImage}
          priceRange={item.priceRange}
          key={item.handle}
        />
      ))}
    </ProductCarousel>
  )
}

export function RecommendedProductsGrid({ productId = '', className = '' }) {
  const { productRecommendations } = useProductsQuery(
    RECOMMENDATIONS_QUERY,
    {
      productId
    },
    'running useProductsQuery(recommended)'
  )

  return (
    <div className={`product-list-grid outerx2 ${className}`}>
      {productRecommendations?.map((item) => {
        const metaProductTitle =
          item.metafields
            ?.filter(Boolean)
            .find((m) => m.key === 'product_title')?.value ?? item.title
        return (
          <ProductCard
            title={metaProductTitle}
            // title={item.title.replace(item.vendor, '')} // TODO: subtitle metafield
            subtitle={item.vendor}
            linkTo={`/products/${item.handle}`}
            shopifyMedia={item.featuredImage}
            priceRange={item.priceRange}
            key={item.handle}
          />
        )
      })}
    </div>
  )
}
