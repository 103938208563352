import React, { useState } from "react"
import { navigate } from "gatsby"
import { useStoreContext } from "../wrappers/store"

function checkAvailability(liveQuantity, staticQuantity) {
	return typeof liveQuantity !== 'undefined' ? liveQuantity > 0 : staticQuantity > 0
}

export function AddToCart ({ 
	selectedVariant,
	...props
}) {

	const { addLineItem, loading } = useStoreContext()

	const [label, setLabel] = useState(props.label)

	const variantId = selectedVariant?.shopifyId
	const liveQuantity = selectedVariant?.quantityAvailable
	const staticQuantity = selectedVariant?.inventoryQuantity
	const availability = selectedVariant && checkAvailability(liveQuantity, staticQuantity)

	const handleClick = () => {
		if (variantId) {
			addLineItem(variantId, (res) => {
				navigate('/cart')
			})
		} else {
			setLabel('Pick an option')
		}
	}

	return (
		<button 
			type="button" 
			className={`btn h1 ${selectedVariant && !availability ? 'disabled' : ''}`} 
			onClick={handleClick} 
			disabled={selectedVariant && !availability}
		>
			{loading ? 'Adding...' : selectedVariant && !availability ? "Sold out" : label}
		</button>
	)
}