import React, { useState } from "react";
import { arrayToObject } from "./helpers";

export function useProductSelection(variants=[], options=[], initialVariantId="") {

	const [hasSelected, setSelected] = useState(false)

	// Get variant based on passed id or the first one
	const initialVariant = variants?.find(v => v.shopifyId === initialVariantId) || variants?.find(v => v.availableForSale) || variants?.[0]

	// Creates object of option key names and the currently selected value
	// const [selectedOptions, setSelectedOptions] = useState({})
	const [selectedOptions, setSelectedOptions] = useState(
		arrayToObject(initialVariant.selectedOptions, 'name', item => {
			return item.value
		})
	)
	
	// Finds the matching variant based on the user selected options
	const selectedVariant = variants?.find(v => {
		const doAllOptionsMatch = v.selectedOptions.reduce((flag, next) => {
			return selectedOptions[next.name] === next.value ? flag * 1 : flag * 0
		}, 1) ? true : false
		return doAllOptionsMatch
	})

	// UI handler for updating the selected options based on an option
	const handleOptionSelect = (key, value) => {
		setSelectedOptions(prev => {
			return {
				...prev,
				[key]: value
			}
		})
		setSelected(true)
	}

	// Map variants to options
	const optionsWithVariants = options.map(option => {
		const valuesWithVariants = option.values.map(optionValue => {
			const matchingVariants = variants.filter(v => v.selectedOptions.find(s => s.value === optionValue))
			return {
				value: optionValue,
				variants: matchingVariants
			} 
		})
		return {
			...option,
			valuesWithVariants
		}
	})
	// console.log(optionsWithVariants)

	return {
		optionsWithVariants,
		selectedOptions,
		selectedVariant: selectedVariant,
		handleOptionSelect,
		hasSelected
	}
}

function OptionButton ({ 
	value, 
	isActive, 
	onClick,
	isHidden
}) {
	return (
		<button 
			type="button" 
			className={`mrx2 a1 upcase ${isActive ? 'active' : ''} ${isHidden ? 'is-hidden' : ''}`} 
			onClick={onClick}
		>
			{value}
		</button>
	)
}

export function ProductOption ({ 
	option={}, 
	handleOptionSelect, 
	selectedOptions
}) {

	const { name, values, valuesWithVariants } = option

	// const checkVariantMatchesSelection = (options) => {
	// 	return options.reduce((acc, next) => {
	// 		return selectedOptions[next.name] === next.value ? acc * 1 : acc * 0
	// 	}, 1)
	// }

	return (
		<div className="product-options outerx2">
			<h5 className="mrx2">{name}</h5>
			{valuesWithVariants ? valuesWithVariants.map(({ value, variants }, i) => {
				const isHidden = variants?.find(v => v.metafields?.find(m => m.key === 'hide_online' && m.value === 'true')) ? true : false
				return (
					!isHidden && (
						<OptionButton 
							value={value} 
							name={name} 
							onClick={() => handleOptionSelect(name, value)}
							isActive={selectedOptions[name] === value} 
							isHidden={isHidden}
							key={`${value}${i}`}
						/>
					)
				)
			}) : values?.map((value, i) => 
				<OptionButton 
					value={value} 
					name={name} 
					onClick={() => handleOptionSelect(name, value)}
					isActive={selectedOptions[name] === value} 
					key={`${value}${i}`}
				/>
			)}
		</div>
	)
}